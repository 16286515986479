import * as whatwg_fetch from 'whatwg-fetch'

export let get = ( url, _options, _callback ) => {

	let options
	let callback
	if ( typeof _options === 'function' ) {
		options = {}
		callback = _options
	}
	if ( typeof _options === 'object' ) {
		options = _options
		callback = _callback
	}

	fetch( url, options )
		.then( (response) => {
			return response.json()
		})
		.then( (body) => {
			if ( callback ) callback(body)
			else return body
		})

}