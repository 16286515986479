export let nodes = {
	home: {},
	build: {},
	about: {}
}


export let build = ( section ) => {


	if ( section === 'home' ) {
		
		nodes.home.wrap = qs( '.section.home' )



		/*
		
			and stuff

		*/

	}

	else if ( section === 'work' ) {



	}
	else if ( section === 'about' ) {

		nodes.about.wrap = qs('.section.about' )

	}
	else {

		nodes.body = qs('body')
		nodes.container_outer = qs('.container')
		nodes.container = qs('.container-inner')

		// Disable Context Menu (appears on long press sometimes)
		/*nodes.container.oncontextmenu = (event) => {
			event.preventDefault()
			event.stopPropagation()
			return false
		}*/

		console.log( '%c nodes ', 'background: #009999; color: #fff', nodes )

	}

}



// Some syntactic sugar, my neighbor

let qs = (selector, node) => {
	if ( node ) {
		return node.querySelector( selector )
	}
	else {
		return document.querySelector(selector)
	}
}

let qsa = (selector, node) => {
	if ( node ) {
		return Array.prototype.slice.call(node.querySelectorAll(selector))
	}
	else {
		return Array.prototype.slice.call(document.querySelectorAll(selector))
	}
}