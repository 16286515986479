export default ( el, video_url, platform, autoplay ) => {

	let vid = document.createElement('video')

	el.innerHTML = ""
	el.classList.add('video-container')
	el.classList.add('loading')

	el.appendChild( vid )

	vid.outerHTML = 
		`<video loop muted playsinline autoplay="true" width="720px" height="720px">
			<source src="${ video_url }" type="video/mp4">
		</video>`

}