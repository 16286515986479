import { build as nodes_build } from './../components/nodes'
import { nodes } from './../components/nodes'
import * as utils from './../components/utils'
import * as controller from './../app/controller'

import { get as ajax } from './../components/ajax'

import Page from './../model/Page'
import template from './../../templates/home.dot'
import * as content from './../components/content'
// import * as socket from './../components/socket'
import * as navigation from './../components/navigation'



let status = {
	init: false
}


export let build = () => {

	// COLLECT nodes within template
	nodes_build( 'home' )





	if ( !status.init ) {

		controller.sync_window_size_state()

	}

	status.init = true
	
}


export let reconnect = () => {

}





// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Register Page

new Page( {

	path: 'home',

	instance: false,

	template: template,

	get_data: ( query ) => {
		return content.all
	},

	parent: false,

	on_load: build

} )





// USED when an non-top level page is navigated to directly

export let pre_build = () => {

	if ( !status.init ) {

		controller.sync_window_size_state()

		nodes_build( 'home' )

	}

	status.init = true

}