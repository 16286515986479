import { build as nodes_build } from './../components/nodes'
import { nodes } from './../components/nodes'
import * as utils from './../components/utils'
import * as controller from './../app/controller'

import { get as ajax } from './../components/ajax'

import Page from './../model/Page'
import template from './../../templates/about.dot'
import * as content from './../components/content'
// import * as socket from './../components/socket'
import * as navigation from './../components/navigation'



export let build = () => {

	// COLLECT nodes within template
	nodes_build( 'about' )



}







// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Register Page

new Page( {

	path: 'about',

	instance: false,

	template: template,

	get_data: ( query ) => {
		return content.all.about
	},

	parent: false,

	on_load: build

} )


