import * as async from 'async-es'

import * as utils from './../components/utils'
import { nodes } from './../components/nodes'
import { all as pages } from './../components/pages'
import mobile from './../components/mobile'
import * as navigation from './../components/navigation'
import * as content from './../components/content'
import * as home from './../sections/home'
import  { get as ajax } from './../components/ajax'


export let logged_in = false

export let is_mobile = true



export let build = () => {

	window.addEventListener( 'resize', resize_handler )

	console.log(
		'%c state ', 'background: #D865C7; color: #fff',
		state
	)

	is_mobile = mobile()

	if ( is_mobile ) {

		nodes.body.classList.add('mobile')

	}

}

export let client_type = 'browser'


let resize_handler = () => {

	let w = state.window_size.w + 0

	sync_window_size_state()

	let ww = state.window_size.w + 0

}


export let sync_body_class = ( template ) => {

	for ( let t in pages ) {
		nodes.body.classList.remove( `section-${ t }` )
	}

	nodes.body.classList.add( `loaded` )
	nodes.body.classList.add( `section-${ template }` )

}

export let sync_window_size_state = () => {

	let w = window.innerWidth
	let h = window.innerHeight

	state.window_size = {
		w: w,
		h: h,
		aspect: w / h,
		d: Math.sqrt( Math.pow( w, 2 ) + Math.pow( h, 2 ) ),
		px: window.devicePixelRatio,
		u_px_norm: { x: w, y: h },
		aspect_vec: { x: 1.0, y: w / h },
		x: 0,
		y: 0,
		u: 1.0 / w,
		v: 1.0 / h,
	}
	
}


export let reset_scroll = () => {

	document.documentElement.scrollTop = 0
	window.scrollTo( 0, 0 )

}


export let  socket_reconnect = () => {

	if ( client_type === 'debug' ) {
		home.reconnect()
	}

}





// SIMPLE state object for tracking certain states across the app

export let state = {

	is_mobile: false,
	
	window_size: {},

	session_id: utils.uuid()

}