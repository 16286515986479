import { build as nodes_build } from './../components/nodes'
import { nodes } from './../components/nodes'
import * as utils from './../components/utils'
import * as controller from './../app/controller'

import { get as ajax } from './../components/ajax'

import Page from './../model/Page'
import template from './../../templates/work.dot'
import * as content from './../components/content'
// import * as socket from './../components/socket'
import * as navigation from './../components/navigation'



export let build = () => {

	// COLLECT nodes within template
	nodes_build( 'work' )



}







// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Register Page

new Page( {

	path: 'work',

	instance: true,

	template: template,

	get_data: ( query ) => {

		let i = Math.max( Math.round( Number( query ) ), 0 )

		let active_works = content.all.works.filter( ( x ) => {
			return x.visible
		} )

		let data = i < active_works.length
			? active_works[ i ]
			: { error: true }
	
		let j = !data.error && i + 1 < active_works.length
			? i + 1
			: 0

		data.next = j
		data.has_next = j !== i

		data.is_mobile = controller.is_mobile

		return data

	},

	parent: false,

	on_load: build

} )


