import { parse_images } from './../app/controller'
import * as utils from './utils'


export let all = { 

	loaded: false,

	"about": {

		"image": "/assets/images/sd-mine-about.png"

	},

	"works": [

		{
			"title": "NFT Mine Offset: Ethereum Kryptowährung Mining&#8209;Rig&nbsp;45&nbsp;MH/s",
			"description": "<p>Simon Denny's NFT Mine Offsets recast each minted NFT as an automated mining unit—a crypto miner—using second-hand Ethereum “mining” computers purchased by the artist from ebay.com <span class='external-link' data-external='https://www.ebay.de/itm/254899062208'>(https://www.ebay.de/itm/254899062208)</span>, where these machines currently fetch anywhere between $3,000–$20,000. Denny has worked with a gaming illustrator to produce detailed 3d portraits of these miners to create new digital artworks. Following their images’ release as NFTs, Denny will retire the mining computers represented from the Ethereum network altogether. Instead of the hardware using the energy typically required to host the intangible collectibles of the NFT wave, the processing power from these machines’ GPUs (Graphical Processing Units) will be donated to environmental research via <span class='external-link' data-external='https://www.climateprediction.net/'>climateprediction.net</span>, a not-for-profit climate modeling project overseen by scientists at the University of Oxford, using volunteer computing to study and predict climate change.</p><p>The artist sees this material and market swap as a kind of offset in the NFT world, mirroring “carbon offsets” that many companies today are leveraging to redress the negative impacts of their business practices. The artwork is a poetic transformation between states—moving energy, matter, likeness, and attention from one network of value to another.</p>",
			"video_small": "/assets/videos/riorock-small.mp4",
			"video_large": "/assets/videos/riorock-large.mp4",
			"nft_url": "https://superrare.co/artwork-v2/nft-mine-offset:-ethereum-kryptow%C3%A4hrung-mining-rig-21644",
			"visible": true,
			"for_sale": true,
			"sold": true,
			"sold_amount": "28.0 ETH ($49,642 at time of sale)",
			"sold_date": "March 18, 2021",
			"drop_date": "Thu Mar 18"
		},

		{

			"title": "NFT Mine Offset: ETH Ethereum Miner 3 GPUs",
			"description": "<p>Simon Denny's NFT Mine Offsets recast each minted NFT as an automated mining unit—a crypto miner—using second-hand Ethereum “mining” computers purchased by the artist from ebay.com where these machines currently fetch anywhere between $3,000–$20,000. Denny has worked with a gaming illustrator to produce detailed 3d portraits of these miners to create new digital artworks. Following their images’ release as NFTs, Denny will retire the mining computers represented from the Ethereum network altogether. Instead of the hardware using the energy typically required to host the intangible collectibles of the NFT wave, the processing power from these machines’ GPUs (Graphical Processing Units) will be donated to environmental research via <span class='external-link' data-external='https://www.climateprediction.net/'>climateprediction.net</span>, a not-for-profit climate modeling project overseen by scientists at the University of Oxford, using volunteer computing to study and predict climate change.</p><p>The artist sees this material and market swap as a kind of offset in the NFT world, mirroring “carbon offsets” that many companies today are leveraging to redress the negative impacts of their business practices. The artwork is a poetic transformation between states—moving energy, matter, likeness, and attention from one network of value to another.</p>",
			"video_small": "/assets/videos/nft-2-small.mp4",
			"video_large": "/assets/videos/nft-2-large.mp4",
			"nft_url": "https://superrare.co/artwork-v2/nft-mine-offset:-eth-ethereum-miner-3-gpus-24095",
			"visible": true,
			"for_sale": true,
			"sold": true,
			"sold_amount": "13.5 ETH ($39,794 at time of sale)",
			"sold_date": "May 1, 2021",
			"drop_date": ""
		}

	]

}


// - - - - - - -  - - - - - - - - - - - - - - - - - - ORGANIZE incoming content

export let store = ( data, callback ) => {

	all = data

	finish_store()

	callback()

}


export let finish_store = () => {

	all.loaded = true

	if ( true ) {

		console.log( 
			'%c content ', 'background: #06C7B3; color: #fff', 
			all
		)

	}

}